import {
  calendar as dateRangeIcon,
  calendarCheckO as dateIcon,
  dashboard as integerIcon,
  database as numberIcon,
  docText as textInputIcon,
  dollar as currencyIcon,
  fileImage as singleFileIcon,
  flyering as multiFileIcon,
  print as wysiwygIcon,
  type as noInputIcon
} from '@/vendor/icons';

export const FormCategory = {
  CREATIVE: {
    value: 'CREATIVE',
    name: 'Creative'
  },
  VERIFICATION: {
    value: 'VERIFICATION',
    name: 'Verification'
  }
};

export const FieldNameOptions = [
  {
    label: 'Creative Files',
    value: 'creative_files'
  },
  {
    label: 'Instructions',
    value: 'instructions'
  },
  {
    label: 'Verification Files',
    value: 'verification_files'
  },
  {
    label: 'Verification Instructions',
    value: 'verification_instructions'
  },
  {
    label: 'Verification Information',
    value: 'verification_text'
  },
  {
    label: 'Click-Through Link',
    value: 'clickthrough_link'
  },
  {
    label: 'Link to post',
    value: 'post_link'
  },
  {
    label: 'Script Tags',
    value: 'script_tags'
  },
  {
    label: 'Creative Text',
    value: 'creative_text'
  },
  {
    label: 'Desktop Video',
    value: 'desktop_video'
  },
  {
    label: 'Desktop Image',
    value: 'desktop_image'
  },
  {
    label: 'Mobile Video',
    value: 'mobile_video'
  },
  {
    label: 'Mobile Image',
    value: 'mobile_image'
  },
  {
    label: 'Is Active',
    value: 'is_active'
  }
];

export const BuilderFields = {
  NO_INPUT: {
    id: 'builder-no-input',
    type: 'NO_INPUT',
    name: '',
    icon: noInputIcon,
    component: null,
    title: 'Info (No Input)',
    label: '',
    default_value: ''
  },
  TEXT: {
    id: 'builder-text-input',
    type: 'TEXT',
    name: 'text_input',
    icon: textInputIcon,
    component: 'ElInput',
    title: 'Text Input',
    label: 'Enter Text',
    placeholder: 'Input Text..',
    default_value: ''
  },
  TEXTAREA: {
    id: 'builder-textarea-input',
    type: 'TEXTAREA',
    name: 'text_area_input',
    icon: textInputIcon,
    component: 'ElInput',
    title: 'Text Box',
    label: 'Enter Text',
    placeholder: 'Input Text..',
    rows: 5,
    default_value: ''
  },
  CURRENCY: {
    id: 'builder-currency-input',
    type: 'CURRENCY',
    name: 'currency_input',
    icon: currencyIcon,
    component: 'ElInput',
    title: 'Currency Input',
    label: 'Amount',
    mask: 'currency',
    placeholder: '$0.00',
    default_value: ''
  },
  NUMBER: {
    id: 'builder-number-input',
    type: 'NUMBER',
    name: 'number_input',
    icon: numberIcon,
    component: 'ElInput',
    title: 'Number Input',
    label: 'Enter Number',
    mask: { name: 'number', params: { precision: 8 } },
    placeholder: '...',
    default_value: ''
  },
  ICON: {
    id: 'builder-text-icon',
    type: 'ICON',
    name: '',
    icon: textInputIcon,
    component: 'StatusIcon',
    title: 'Icon Input',
    label: 'Choose Icon',
    placeholder: '',
    default_value: ''
  },
  INTEGER: {
    id: 'builder-integer-input',
    type: 'INTEGER',
    name: 'integer_input',
    icon: integerIcon,
    component: 'ElInput',
    title: 'Integer Input',
    label: 'Enter Integer',
    mask: 'integer',
    placeholder: '...',
    default_value: ''
  },
  BOOLEAN: {
    id: 'builder-boolean-input',
    type: 'BOOLEAN',
    name: 'boolean_input',
    icon: textInputIcon,
    component: 'CustomElSwitch',
    title: 'Yes / No Switch',
    label: 'Yes or No?',
    default_value: false,
    props: {
      right: true,
      greenRed: true,
      activeText: 'Yes',
      inactiveText: 'No'
    }
  },
  DATE: {
    id: 'builder-date-input',
    type: 'DATE',
    name: 'date_input',
    icon: dateIcon,
    component: 'ElDatePicker',
    title: 'Date Picker',
    label: 'Pick Date',
    default_value: null,
    props: {
      format: 'MM/dd/yyyy'
    }
  },
  DATE_RANGE: {
    id: 'builder-date-range-input',
    type: 'DATE_RANGE',
    name: 'date_range_input',
    icon: dateRangeIcon,
    component: 'ElDatePicker',
    title: 'Date Range Picker',
    label: 'Pick Date Range',
    default_value: null,
    props: {
      format: 'MM/dd/yyyy',
      type: 'daterange',
      rangeSeparator: '-',
      startPlaceholder: 'Start date',
      endPlaceholder: 'End date'
    }
  },
  MULTI_FILE: {
    id: 'builder-multi-file-input',
    type: 'MULTI_FILE',
    name: 'multi_file_input',
    icon: multiFileIcon,
    component: 'UploadBox',
    title: 'Multi File Upload',
    label: 'Upload Files',
    inputOnComplete: true,
    default_value: null,
    props: {
      canDelete: true,
      enablePreview: true,
      multiple: true
    }
  },
  SINGLE_FILE: {
    id: 'builder-single-file-input',
    type: 'SINGLE_FILE',
    name: 'single_file_input',
    icon: singleFileIcon,
    component: 'UploadBox',
    inputOnComplete: true,
    title: 'Single File Upload',
    label: 'Upload File',
    default_value: null,
    props: {
      class: 'w-48',
      canDelete: true,
      enablePreview: true,
      multiple: false
    }
  },
  WYSIWYG: {
    id: 'builder-text-wysiwyg',
    type: 'WYSIWYG',
    name: 'rich_text_input',
    icon: wysiwygIcon,
    component: 'TextEditor',
    title: 'WYSIWYG Editor',
    label: 'Description',
    placeholder: 'Style your text...',
    default_value: '',
    previewProps: {
      config: {
        height: 120,
        menubar: false,
        toolbar: [
          'bold italic strikethrough underline | link image emoticons | fullscreen'
        ]
      }
    }
  }
};

export const FormBuilderFieldList = [
  BuilderFields.NO_INPUT,
  BuilderFields.TEXT,
  BuilderFields.TEXTAREA,
  BuilderFields.CURRENCY,
  BuilderFields.NUMBER,
  BuilderFields.INTEGER,
  BuilderFields.BOOLEAN,
  BuilderFields.DATE,
  BuilderFields.DATE_RANGE,
  BuilderFields.MULTI_FILE,
  BuilderFields.SINGLE_FILE,
  BuilderFields.WYSIWYG
];

export const DefaultCreativeBuilderFields = [
  { ...BuilderFields.MULTI_FILE, name: 'creative_files' },
  { ...BuilderFields.WYSIWYG, name: 'creative_text' }
];

export const DefaultVerificationBuilderFields = [
  { ...BuilderFields.MULTI_FILE, name: 'verification_files' },
  { ...BuilderFields.WYSIWYG, name: 'verification_text' }
];
